export default [
    {
        title: 'Admin',
        route: 'dashboard-mechtech',
        icon: 'HomeIcon',
    },
    {
        title: 'Admin 2',
        route: 'courses',
        icon: 'BookIcon',
    },
  ]