export default [
    {
        title: 'Dashboards',
        route: 'dashboard-mechtech',
        icon: 'HomeIcon',
    },
    {
        title: 'Courses',
        route: 'courses',
        icon: 'BookIcon',
    },
  ]